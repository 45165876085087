import React, { useContext } from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import AspectRatio from "../../components/AspectRatio";
import Image from "../../components/Image";
import LinkTo from "../../components/LinkTo";
import Section from "../../components/Section";
import Button from "../../components/Button";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import ActionButtons from "../../components/ActionButtons";
import mdToPlainText from "../../utilities/mdToPlainText";
import longTextToShort from "../../utilities/longTextToShort";

const OurWork = ({ sectionSx, data, containerSx }) => {
  const theme = useContext(ThemeContext);
  return (
    <Section
      containerSx={{
        ...containerSx,
      }}
      sx={{ bg: "white", ...sectionSx }}
    >
      {!data.textContent.heading && (
        <Box
          as="h3"
          sx={{
            color: "primaryMedium",
            fontFamily: "secondary",
            fontSize: 2,
            fontWeight: "bold",
            letterSpacing: 4,
            lineHeight: 3,
            mb: 3,
            textTransform: "uppercase",
          }}
        >
          Our Work
        </Box>
      )}
      {data.textContent && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            mb: 4,
            mx: "auto",
            width: ["100%", null, null, "70%"],
          }}
        >
          <MarkdownToHtml
            contentChildren={
              data.actionButtons &&
              data.actionButtons.length > 0 &&
              data.actionButtons.some(
                (i) => !i.hideButton && i.buttonLabel
              ) && (
                <ActionButtons
                  buttons={data.actionButtons}
                  wrapperSx={{
                    mt: 2,
                  }}
                />
              )
            }
            heading={data.textContent?.heading}
            source={data.textContent?.content}
            sx={{
              ".md_content": {
                alignItems: ["flex-start", null, null, "center"],
                display: "flex",
                flex: "auto",
                flexDirection: "column",
                mt: 3,
              },
              ".md_heading": {
                alignItems: ["flex-start", null, null, "center"],
                display: "flex",
                flexDirection: "column",
                h3: {
                  color: "gulfStreamGray",
                },
                textAlign: ["left", null, null, "center"],
                width: "100%",
              },
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              h3: "primaryMedium",
              width: "100%",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          m: -3,
        }}
      >
        {data.list.map((item, index) => (
          <Box
            key={`${camelCase(item.serviceName)}-${index}`}
            sx={{
              display: "flex",
              flexDirection: "column",
              p: "1.5rem",
              width: ["100%", null, "calc(100% / 2)", "calc(100% / 3)"],
            }}
          >
            <LinkTo
              hoverSx={{
                bg: "primarySoft",
                boxShadow: 2,
                button: {
                  bg: "primary",
                  color: "white",
                },
              }}
              sx={{
                bg: "white",
                boxShadow: 1,
                button: {
                  bg: "transparent",
                  color: "primary",
                },
                display: "flex",
                flex: "auto",
                flexDirection: "column",
                position: "relative",
                transition: "all .45s",
              }}
              to={`/work#${camelCase(item.serviceName)}`}
            >
              <Box sx={{ bg: "catalinaBlue", p: 3 }}>
                <AspectRatio
                  ratio={[16, 9]}
                  sx={{ color: "inherit", width: "100%" }}
                >
                  <Image img={item.image} sx={{ mx: "auto" }} />
                </AspectRatio>
              </Box>
              <Box sx={{ p: 4 }}>
                <MarkdownToHtml
                  source={`### ${item.workingField}\n\n ${item.textContent.heading}`}
                  sx={{
                    h2: {
                      fontSize: 3,
                      fontWeight: "medium",
                      mb: 4,
                    },
                    h3: {
                      border: ".4rem solid",
                      borderColor: "primaryLight",
                      borderWidth: "0 0 0 .6rem",
                      color: "text.primary",
                      fontSize: 0,
                      mb: 3,
                      ml: -4,
                      pl: `calc(${theme.space[3]} * 1.4)`,
                      py: 1,
                    },
                  }}
                />
                <Box sx={{ mb: 4 }}>
                  {item.textContent.content &&
                    longTextToShort(
                      mdToPlainText(item.textContent.content),
                      140
                    )}
                </Box>
                <Button
                  sx={{
                    bottom: 0,
                    position: "absolute",
                    right: 0,
                    transition: "all .45s",
                  }}
                >
                  <AspectRatio sx={{ color: "inherit", width: "2.4rem" }}>
                    <Image svgIcon="arrow-right" />
                  </AspectRatio>
                </Button>
              </Box>
            </LinkTo>
          </Box>
        ))}
      </Box>
    </Section>
  );
};

OurWork.propTypes = {
  containerSx: PropTypes.shape({}),
  data: PropTypes.PropTypes.shape({
    actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
    list: PropTypes.arrayOf(
      PropTypes.PropTypes.shape({
        content: PropTypes.string,
        image: PropTypes.string,
        serviceName: PropTypes.string,
      })
    ),
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }),
  }).isRequired,
  sectionSx: PropTypes.shape({}),
};

OurWork.defaultProps = {
  containerSx: {},
  sectionSx: {},
};

export default OurWork;
