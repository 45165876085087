import React from "react";
import { Router } from "@reach/router";
import HomePage from "../components/HomePage";
import NotFoundPage from "./404";

const HomePageRouter = (props) => (
  <Router>
    <HomePage {...props} path="/" />
    <NotFoundPage default />
  </Router>
);

export default HomePageRouter;
