const longTextToShort = (text, charLimit) => {
  let trimmedString = text.substr(0, charLimit);
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  return `${trimmedString}...`;
};

export default longTextToShort;
