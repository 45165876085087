import React from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import Box from "../../base/Box";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import LinkTo from "../../components/LinkTo";
import Button from "../../components/Button";
import AspectRatio from "../../components/AspectRatio";
import Image from "../../components/Image";
import Section from "../../components/Section";
import ActionButtons from "../../components/ActionButtons";

const CardsGrid = ({
  sectionSx,
  data,
  containerSx,
  contentSx,
  contentWrapperSx,
  servicesCardContentSx,
  servicesCardLinkSx,
  servicesCardSx,
}) => (
  <Section
    containerSx={{
      display: "flex",
      flexDirection: "column",
      maxWidth: [
        null,
        "maxWidths.sm",
        "maxWidths.md",
        "maxWidths.lg",
        "maxWidths.xl",
      ],
      ...containerSx,
    }}
    sx={{ bg: "white", mb: 0, mt: 0, ...sectionSx }}
  >
    {data.textContent && (
      <>
        {!data.textContent.heading && (
          <Box
            as="h3"
            sx={{
              color: "primaryLight",
              fontFamily: "secondary",
              fontSize: 2,
              fontWeight: "bold",
              letterSpacing: 4,
              lineHeight: 3,
              textTransform: "uppercase",
            }}
          >
            Key Competencies
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 4,
            ...contentWrapperSx,
          }}
        >
          <MarkdownToHtml
            heading={data.textContent.heading}
            source={data.textContent.content}
            sx={{
              ".md_content": {
                flex: "auto",
                width: ["100%", null, null, "50%"],
              },
              ".md_heading": {
                pr: [null, null, null, 6],
                width: ["100%", null, null, "50%"],
              },
              display: "flex",
              flexWrap: "wrap",
              h3: {
                color: "primaryLight",
              },
              ...contentSx,
            }}
          />
          {data.actionButtons &&
            data.actionButtons.length > 0 &&
            data.actionButtons.some((i) => !i.hideButton && i.buttonLabel) && (
              <ActionButtons
                buttons={data.actionButtons}
                wrapperSx={{
                  mt: 0,
                }}
              />
            )}
        </Box>
      </>
    )}
    <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap", mx: "-1.5rem" }}>
        {data.services
          .filter((service) => !service.hideService && service.keyCompetency)
          .map((service, index) => {
            const serviceName = camelCase(service.serviceName);
            return (
              <Box
                key={`${serviceName}-${index}`}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: ["100%", "calc(100% / 2)", null, "calc(100% / 4)"],
                  p: "1.5rem",
                  ...servicesCardSx,
                }}
              >
                <LinkTo
                  hoverSx={{
                    bg: "primarySoft",
                    boxShadow: 2,
                    button: {
                      bg: "primary",
                      color: "white",
                    },
                  }}
                  sx={{
                    bg: "white",
                    boxShadow: 1,
                    button: {
                      bg: "transparent",
                      color: "primary",
                    },
                    display: "flex",
                    flex: "auto",
                    flexDirection: "column",
                    p: 3,
                    pb: 6,
                    position: "relative",
                    transition: "all .45s",
                    ...servicesCardLinkSx,
                  }}
                  to={`/services/#${serviceName}`}
                >
                  <MarkdownToHtml
                    source={service.briefing}
                    sx={{
                      h2: {
                        fontSize: 3,
                        fontWeight: "medium",
                        mb: 4,
                      },
                      h3: {
                        border: ".4rem solid",
                        borderColor: "primaryLight",
                        borderWidth: "0 0 0 .6rem",
                        color: "text.primary",
                        fontSize: 0,
                        mb: 3,
                        ml: -3,
                        pl: 3,
                        py: 1,
                      },
                      ...servicesCardContentSx,
                    }}
                  />
                  <Button
                    sx={{
                      bottom: 0,
                      position: "absolute",
                      right: 0,
                      transition: "all .45s",
                    }}
                  >
                    <AspectRatio sx={{ color: "inherit", width: "2.4rem" }}>
                      <Image svgIcon="arrow-right" />
                    </AspectRatio>
                  </Button>
                </LinkTo>
              </Box>
            );
          })}
      </Box>
    </Box>
  </Section>
);

CardsGrid.propTypes = {
  containerSx: PropTypes.shape({}),
  contentSx: PropTypes.shape({}),
  contentWrapperSx: PropTypes.shape({}),
  data: PropTypes.PropTypes.shape({
    actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
    services: PropTypes.arrayOf(
      PropTypes.shape({
        briefing: PropTypes.string,
        hideService: PropTypes.bool,
        keyCompetency: PropTypes.bool,
        serviceName: PropTypes.string,
      })
    ),
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }),
  }).isRequired,
  sectionSx: PropTypes.shape({}),
  servicesCardContentSx: PropTypes.shape({}),
  servicesCardLinkSx: PropTypes.shape({}),
  servicesCardSx: PropTypes.shape({}),
};

CardsGrid.defaultProps = {
  containerSx: {},
  contentSx: {},
  contentWrapperSx: {},
  sectionSx: {},
  servicesCardContentSx: {},
  servicesCardLinkSx: {},
  servicesCardSx: {},
};

export default CardsGrid;
