import React from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import Box from "../../base/Box";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import AspectRatio from "../../components/AspectRatio";
import Section from "../../components/Section";
import BgImage from "../../components/BgImage";
import LinkTo from "../../components/LinkTo";
import TestimonialSlider from "../../components/TestimonialSlider";

const Testimonials = ({ sectionSx, data, containerSx, ...rest }) => {
  const { textContent, clients } = data;
  const isClients = clients && clients.length > 0;
  const clientsTestimonials = clients.filter((client) => client.testimonial);
  const setLogoImage = (client, ratio) => (
    <AspectRatio ratio={ratio} title={client.clientName}>
      <BgImage
        name={client.logo}
        page="clients"
        sx={{
          backgroundPosition: "center",
          backgroundSize: "contain",
          position: "absolute",
        }}
      />
    </AspectRatio>
  );
  return (
    <>
      <Section
        containerSx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: [null, "maxWidths.sm", "maxWidths.md", "maxWidths.lg"],
          ...containerSx,
        }}
        sx={{ bg: "grays.0", ...sectionSx }}
        {...rest}
      >
        {!textContent.heading && (
          <Box
            as="h3"
            sx={{
              color: "primaryLight",
              fontFamily: "secondary",
              fontSize: 2,
              fontWeight: "bold",
              letterSpacing: 4,
              lineHeight: 3,
              textTransform: "uppercase",
            }}
          >
            Testimonials
          </Box>
        )}

        {textContent && (
          <MarkdownToHtml
            heading={textContent.heading}
            source={textContent.content}
            sx={{
              textAlign: "center",
            }}
          />
        )}
        {clientsTestimonials && clientsTestimonials.length > 0 && (
          <TestimonialSlider
            testimonials={clientsTestimonials.map((client) => {
              const { authorName, authorDesignation, testimonial, logo } =
                client;
              const author = `${
                authorName ? `#### **${authorName}**\n\n` : ""
              }${authorDesignation ? `##### ${authorDesignation}` : ""}`;
              return {
                author,
                content: testimonial,
                page: "clients",
                thumb: logo,
              };
            })}
            thumbRatio={[16, 9]}
          />
        )}
      </Section>
      <Section>
        {isClients && (
          <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                mx: -3,
              }}
            >
              {clients.map((client, index) => (
                <Box
                  key={`${camelCase(client.logo)}-${index}`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: ["50%", "calc(100% / 3)", null, "calc(100% / 5)"],
                    px: 3,
                    py: 2,
                    width: "100%",
                  }}
                >
                  {client.clientUrl ? (
                    <LinkTo
                      isLink={client.clientUrl}
                      target="_blank"
                      title={client.clientName}
                    >
                      {setLogoImage(client, [16, 9])}
                    </LinkTo>
                  ) : (
                    setLogoImage(client, [16, 9])
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Section>
    </>
  );
};

Testimonials.propTypes = {
  containerSx: PropTypes.shape({}),
  data: PropTypes.shape({
    clients: PropTypes.arrayOf(
      PropTypes.shape({
        clientName: PropTypes.string,
        clientUrl: PropTypes.string,
        logo: PropTypes.string,
        testimonial: PropTypes.string,
      })
    ),
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }),
  }).isRequired,
  sectionSx: PropTypes.shape({}),
};

Testimonials.defaultProps = {
  containerSx: {},
  sectionSx: {},
};

export default Testimonials;
