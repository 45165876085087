import React, { useContext } from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import MarkdownToHtml from "../MarkdownToHtml";
import AspectRatio from "../AspectRatio";
import BgImage from "../BgImage";
import Carousel from "../Carousel";
import CustomArrow from "../Carousel/CustomArrow";
import Image from "../Image";

const TestimonialSlider = ({ testimonials, sx, thumbSx, thumbRatio }) => {
  const theme = useContext(ThemeContext);
  const prevStyled = {
    left: [`50%`, null, null, `calc(25% + ${theme.space[6]})`],
    transform: [
      `translate(calc(-50% - 6rem), -50%)`,
      null,
      null,
      "translate(0, -50%)",
    ],
  };
  const nextStyled = {
    left: ["50%", null, null, `calc(25% + ${theme.space[6]} + 6rem )`],
    transform: [
      `translate(calc(-50% + 0rem), -50%)`,
      null,
      null,
      "translate(0, -50%)",
    ],
  };
  const controlStyled = {
    bg: "primary",
    bottom: "auto",
    color: "white",
    position: "absolute",
    top: "calc(100% + 4.8rem)",
    transform: "translate(0, 0)",
  };
  return (
    <Carousel
      settings={{
        arrows: false,
        dots: true,
        nextArrow: <CustomArrow nextSx={nextStyled} sx={controlStyled} />,
        prevArrow: <CustomArrow prev prevSx={prevStyled} sx={controlStyled} />,
      }}
      sx={{
        ".slick-active": {
          button: { ":before": { color: "grays.1", opacity: "100%" } },
        },
        ".slick-dots": {
          li: {
            // bottom: "-4rem",
            button: {
              ":before": { fontSize: 2 },
              ":hover": {
                ":before": { color: "grays.2", opacity: "25%" },
              },
            },
            // height: "5rem",
          },
          mt: 2,
          top: "100%",
        },
        ".slick-slide": {
          my: "auto",
          py: 3,
        },
        ...sx,
      }}
    >
      {testimonials.map((testimonial, index) => {
        const { author, content, page, thumb } = testimonial;
        return (
          <Box
            key={`${camelCase(author)}-${index}`}
            sx={{
              alignItems: "center",
              display: "flex !important",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                color: "skyBlueLight",
                fontFamily: "secondary",
                fontSize: "20rem",
                fontWeight: "bold",
                height: "10rem",
                lineHeight: 1,
                // mb: -5,
                // mt: [5, null, null, 6],
              }}
            >
              “
            </Box>
            <MarkdownToHtml
              source={content}
              sx={{
                color: "primary",
                mb: 3,
                p: {
                  fontSize: 3,
                  fontWeight: "medium",
                },
                textAlign: "center",
              }}
            />
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: ["column", null, null, "row"],
                justifyContent: "center",
                width: "100%",
              }}
            >
              {author && (
                <MarkdownToHtml
                  heading={author}
                  sx={{
                    color: "primary",
                    display: "flex",
                    flexDirection: ["row", null, null, "column"],
                    fontSize: 3,
                    pr: [0, null, null, 3],
                    textAlign: "center",
                  }}
                />
              )}
              {thumb && (
                <AspectRatio
                  ratio={thumbRatio}
                  sx={{ maxWidth: "20rem", ...thumbSx }}
                >
                  {page ? (
                    <BgImage
                      name={thumb}
                      page={page}
                      sx={{
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        position: "absolute",
                      }}
                    />
                  ) : (
                    <Image
                      img={thumb}
                      sx={{
                        height: "auto",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  )}
                </AspectRatio>
              )}
            </Box>
          </Box>
        );
      })}
    </Carousel>
  );
};

TestimonialSlider.propTypes = {
  sx: PropTypes.shape({}),
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      content: PropTypes.string,
      page: PropTypes.string,
      thumb: PropTypes.string,
    })
  ).isRequired,
  thumbRatio: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number])
  ),
  thumbSx: PropTypes.shape({}),
};

TestimonialSlider.defaultProps = {
  sx: {},
  thumbRatio: [1, 1],
  thumbSx: {},
};

export default TestimonialSlider;
