/* Everestek Website 2021 */
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { orderBy } from "lodash";
import Box from "../../base/Box";
import HomePageContainer from "../../containers/HomePageContainer";
import BgImage from "../BgImage";
import HeroBanner from "../HeroBanner";
import AspectRatio from "../AspectRatio";
import MarkdownToHtml from "../MarkdownToHtml";
import ActionButtons from "../ActionButtons";
import Testimonials from "../../sections/Testimonials";
import PointsColumns from "../../sections/PointsColumns";
import CardsGrid from "../../sections/CardsGrid";
import PageIntro from "../../sections/PageIntro";
import Insights from "../../sections/Insights";
import OurWork from "../../sections/OurWork";

const HomePage = () => {
  const theme = useContext(ThemeContext);
  return (
    <HomePageContainer>
      {(homePageData, aboutData, servicesData, workData, clientsData) => {
        // homeContent
        const pageId = "home";
        const homeContent = homePageData
          .map((ele) => (ele.id === pageId ? ele : null))
          .find((el) => el);
        const {
          keyCompetencies: homePageKeyCompetencies,
          ourWork: homePageOurWork,
          ourApproach: homePageOurApproach,
          heroBanner,
          insights,
          ourClients,
          introduction,
        } = homeContent;
        // aboutContent
        const aboutContent = aboutData.find((data) => data.id === "about");
        const { ourApproach: defaultOurApproach } = aboutContent;

        // servicesContent
        const servicesContent = servicesData.find(
          (data) => data.id === "services"
        );
        const { keyCompetencies: defaultKeyCompetencies, services } =
          servicesContent;

        // workData
        const workServicesContent = workData
          .map((ele) => ele.id === "work" && ele)
          .find((el) => el);
        const { services: workServices } = workServicesContent;

        // clientsContent
        const clientsContent = clientsData.find(
          (data) => data.id === "clients"
        );
        const { textContent: defaultClientContent, clients } = clientsContent;

        // introductionContent

        const introductionData = {
          ...introduction,
          images:
            introduction?.images &&
            orderBy(introduction.images, ["orderNumber"], ["asc"]),
        };

        const isKeyCompetencies =
          homePageKeyCompetencies || defaultKeyCompetencies;
        const showKeyCompetencies =
          isKeyCompetencies &&
          !homePageKeyCompetencies.hideSection &&
          services.length > 0;
        const KeyCompetenciesContent =
          showKeyCompetencies && isKeyCompetencies.textContent;

        const showOurApproach =
          homePageOurApproach &&
          !homePageOurApproach.hideSection &&
          defaultOurApproach?.approaches?.length > 0;
        const ourApproachContent =
          (showOurApproach &&
            (homePageOurApproach?.textContent?.heading ||
              homePageOurApproach?.textContent?.content) &&
            homePageOurApproach?.textContent) ||
          defaultOurApproach?.textContent;

        const isInsights = insights && !insights.hideSection;
        const insightsContent = isInsights && insights.textContent;
        const insightsActionButtons =
          (isInsights && insights.actionButtons) || [];

        const isOurWork = homePageOurWork && !homePageOurWork.hideSection;
        const ourWorkContent = isOurWork && homePageOurWork.textContent;
        const ourWorkActionButtons =
          (isOurWork && homePageOurWork.actionButtons) || [];
        const ourWorkServiceList =
          isOurWork &&
          workServices &&
          workServices.length > 0 &&
          workServices.filter((i) => !i.hideService).splice(0, 4);

        const isOurClients = ourClients && !ourClients.hideSection;
        const clientContent =
          (isOurClients &&
            (ourClients.textContent?.heading ||
              ourClients.textContent?.content) &&
            ourClients.textContent) ||
          defaultClientContent;

        return (
          <>
            {heroBanner && !heroBanner.hideSection && (
              <HeroBanner
                bannerImage={heroBanner?.backgroundImage}
                containerSx={{
                  flexDirection: ["column", null, null, "row"],
                }}
                sx={{
                  backgroundPosition: "center top",
                  minHeight: ["25rem", null, null, "50rem"],
                  pb: [
                    4,
                    null,
                    null,
                    null,
                    `calc(${theme.space[6]} * 2)`,
                    `calc(${theme.space[6]} * 2)`,
                  ],
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{ maxWidth: ["100%", null, null, "50%", null, "50%"] }}
                >
                  <MarkdownToHtml
                    content={heroBanner?.textContent?.content}
                    contentSx={{ color: "white", mt: [4, null, null, null, 5] }}
                    heading={heroBanner?.textContent?.heading}
                    headingSx={{ maxWidth: "90%" }}
                    sx={{
                      h1: { color: "skyBlueLight" },
                      maxWidth: ["100%", null, null, null, "90%"],
                    }}
                  />
                  {heroBanner.actionButtons &&
                    heroBanner.actionButtons.length > 0 && (
                      <ActionButtons
                        buttons={heroBanner.actionButtons}
                        wrapperSx={{ mt: [4, null, null, null, 5] }}
                      />
                    )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: ["center", null, null, "flex-end"],
                    mt: [4, null, null, null, 0],
                    position: "relative",
                    width: ["auto", null, null, "100vw"],
                  }}
                >
                  <AspectRatio ratio={[3, 2]}>
                    <BgImage
                      name={heroBanner.bannerImage}
                      page={homeContent.id}
                      sx={{
                        backgroundPosition: "right",
                        backgroundSize: "cover",
                        left: 0,
                        position: "absolute",
                        top: 0,
                      }}
                    />
                  </AspectRatio>
                </Box>
              </HeroBanner>
            )}
            {introduction && !introduction.hideSection && (
              <PageIntro
                buttonSx={{ color: "catalinaBlue" }}
                containerSx={{
                  flexDirection: ["column-reverse", null, null, "row-reverse"],
                  maxHeight: ["auto", null, null, null, "70vh", "55vh"],
                }}
                contentSx={{
                  color: "grays.1",
                  h2: {
                    color: "black",
                    width: ["100%", null, null, null, null, "70%"],
                  },
                  h3: {
                    color: "gulfStreamGray",
                  },
                  width: ["100%", null, null, "90%"],
                }}
                contentWrapperSx={{
                  mb: [4, null, null, null, 0],
                  mt: [null, null, null, null, 2, 6],
                  pl: 0,
                  pr: 0,
                }}
                data={introductionData}
                imageRatio={[34, 30]}
                imageSx={{
                  maxWidth: "auto",
                  mt: 0,
                  my: 0,
                  pt: 0,
                  px: 0,
                }}
                imageWrapperSx={{
                  display: "grid",
                  gridAutoFlow: "column",
                  gridColumnGap: ["2rem", null, null, "3.5rem", "6.5rem"],
                  gridRowGap: ["2rem", null, null, 0, "5.5rem"],
                  gridTemplateColumns: "auto auto",
                  gridTemplateRows: "auto auto",
                  mx: 0,
                  transform: [
                    "none",
                    null,
                    null,
                    null,
                    "translateY(-10vw)",
                    "translateY(-10vw)",
                  ],
                }}
                sectionSx={{
                  bg: "white",
                  zIndex: 1,
                }}
              />
            )}
            {showKeyCompetencies && (
              <CardsGrid
                containerSx={{
                  alignItems: "center",
                  maxWidth: [
                    null,
                    "maxWidths.sm",
                    "maxWidths.md",
                    "maxWidths.lg",
                    "maxWidths.xl",
                    "maxWidths.xxl",
                  ],
                }}
                contentSx={{
                  ".md_content": {
                    flex: "auto",
                    pt: [null, null, null, `calc(${theme.space[3]} * 2)`],
                    width: ["100%", null, null, "60%"],
                  },
                  ".md_heading": {
                    pr: [null, null, null, 6],
                    width: ["100%", null, null, "40%"],
                  },
                  color: "grays.1",
                  h2: { color: "black" },
                  h3: { color: "gulfStreamGray" },
                }}
                contentWrapperSx={{ mb: 0, width: ["100%", null, null, "80%"] }}
                data={{ services, textContent: KeyCompetenciesContent }}
                sectionSx={{
                  backgroundImage: [
                    `linear-gradient(${theme.colors.grays[0]} 100%, #ffffff 0%)`,
                    null,
                    null,
                    `linear-gradient(${theme.colors.grays[0]} 60%, #ffffff 0%)`,
                  ],
                }}
                servicesCardContentSx={{
                  h2: {
                    fontSize: [3, null, null, null, 4],
                    fontWeight: "medium",
                    mb: 4,
                  },
                  h3: {
                    border: ".4rem solid",
                    borderColor: "primaryLight",
                    borderWidth: "0 0 0 .6rem",
                    color: "text.primary",
                    fontSize: 0,
                    mb: 3,
                    ml: -4,
                    pl: `calc(${theme.space[3]} * 1.4)`,
                    py: 1,
                  },
                }}
                servicesCardLinkSx={{
                  p: 4,
                  pb: [6, null, null, null, 7],
                }}
                servicesCardSx={{ width: ["100%", null, null, "auto"] }}
              />
            )}
            {isOurWork && (
              <OurWork
                data={{
                  actionButtons: ourWorkActionButtons,
                  list: ourWorkServiceList,
                  textContent: ourWorkContent,
                }}
              />
            )}
            {showOurApproach && (
              <PointsColumns
                contentDataSx={{
                  h3: {
                    color: "catalinaBlue",
                    fontSize: 3,
                    fontWeight: "medium",
                    letterSpacing: 1,
                    textTransform: "capitalize",
                  },
                }}
                data={{
                  ...defaultOurApproach,
                  textContent: ourApproachContent,
                }}
                headingSx={{
                  h2: {
                    ml: -2,
                    pl: 2,
                  },
                  h3: { color: "gulfStreamGray", mb: 0 },
                  mb: 5,
                }}
                isHome
                sectionSx={{ bg: "grays.0", pb: 7, pt: 7 }}
              />
            )}
            {isInsights && (
              <Insights
                blogCardHoverSx={{
                  bg: "catalinaBlue",
                  button: {
                    bg: "transparent",
                    color: "white",
                    opacity: 1,
                  },
                  color: "white",
                  h2: { color: "white" },
                }}
                blogCardSx={{
                  bg: "catalinaBlue",
                  color: "white",
                  h2: { color: "skyBlueLight" },
                  pb: [2, null, null, 3],
                }}
                buttonSx={{ color: "white" }}
                buttonWrapperSx={{ mt: 3 }}
                containerSx={{
                  maxWidth: [
                    null,
                    "maxWidths.sm",
                    "maxWidths.md",
                    "maxWidths.lg",
                    "maxWidths.xl",
                    "maxWidths.xxl",
                  ],
                }}
                data={{
                  actionButtons: insightsActionButtons,
                  textContent: insightsContent,
                }}
                imageAspectRatio={[16, 9]}
                isHomePage
                sectionSx={{
                  bg: "catalinaBlue",
                }}
                topContentSx={{
                  ".md_content": {
                    color: "white",
                    flex: "auto",
                    mt: 4,
                    width: ["100%", null, null, "65%"],
                  },
                  ".md_heading": {
                    pr: [null, null, null, 6],
                    width: ["100%", null, null, "35%"],
                  },
                  h2: { color: "skyBlueLight", fontWeight: "bold", mb: 0 },
                  h3: { color: "gulfStreamGray" },
                  mb: 4,
                  width: ["100%", null, null, "90%"],
                }}
                topContentWrapperSx={{ alignItems: "center" }}
              />
            )}
            {isOurClients && (
              <Testimonials
                data={{
                  clients,
                  textContent: clientContent,
                }}
              />
            )}
          </>
        );
      }}
    </HomePageContainer>
  );
};

export default HomePage;
